.Popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(30, 27, 27, 0.53);
  z-index: 1000;
}
.Popup .window {
  width: 702px;
  max-width: 100%;
}
.Popup .window .popup-nav {
  background-color: var(--main-color);
  padding: 21px 40px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 13px 13px 0 0;
}
.Popup .window .cont {
  background-color: white;
  padding: 40px 21px;
  border-radius: 0 0 13px 13px;
}
@media (max-width: 576px) {
  .Popup .window .popup-nav {
    padding: 16px;
  }
}
