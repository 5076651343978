.EmptyPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(30 27 27 / 17%);
  z-index: 1000;
}
.EmptyPopup .window {
  max-width: 100%;
}
