.PointCard:first-child {
  background-color: #080a1f;
  color: white;
}
.PointCard:last-child {
  background-color: #f2c94c;
  color: white;
}
.PointCard .icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #d9d9d940;
}
.PointCard .text span:first-child {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.PointCard .text span:last-child {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
