.example-thumb.example-thumb-1,
.example-thumb.example-thumb-0 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f2c94c;
  filter: drop-shadow(0px 4px 4px rgba(53, 53, 53, 0.15));
  cursor: pointer;
  top: 50%;
  transform: translateY(-30%);
}
.example-thumb.example-thumb-1,
.example-thumb.example-thumb-0:focus-visible {
  outline: none !important;
}

.example-track.example-track-0 {
  border-radius: 5px;
  background: #ebebeb;
  height: 6px;
}
.example-track.example-track-1 {
  border-radius: 5px;
  background: #f2c94c;
  height: 6px;
}
.example-track.example-track-2 {
  border-radius: 5px;
  background: #ebebeb;
  height: 6px;
}
.Range h4 {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 35px;
}
.Range .range-cont {
  position: relative;
  width: 100%;
  height: 100%;
}
.Range .range-cont span {
  display: block;
  position: absolute;
  top: -140%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 600;
}
