
.AdsDetails .Title h3 {
  max-width: 580px;
  line-height: 39px;
}
.AdsDetails .Title .time {
  color: #acacac;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.AdsDetails .Title .owner {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
