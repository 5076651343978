.HeaderDetailsOrder {
  max-width: 400px;
}
.HeaderDetailsOrder h3 {
  font-size: 20px;
  font-weight: 600;
}
.HeaderDetailsOrder p {
  color: #6a6a6a;
}
