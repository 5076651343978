/* Side List */
.Messages .list-side {
  width: 350px;
  position: absolute;
  top: 0px;
  height: 100%;
  transition: 0.5s;
  z-index: 50;
}
.Vendor[dir="ltr"] .Messages .list-side {
  left: -370px;
}
.Vendor[dir="rtl"] .Messages .list-side {
  right: -370px;
}
.Vendor[dir="ltr"] .Messages .list-side.direct {
  left: 0;
}
.Vendor[dir="rtl"] .Messages .list-side.direct {
  right: 0;
}
/* ScrollComponent */
.Messages .list-side .all-message .ScrollarComponent {
  height: 100%;
}
.Messages .list-side .all-message .ScrollarComponent .link-message:hover {
  transform: translateX(5px);
}
/* Side List */
/* Footer */
.Messages .send-message {
  box-shadow: 0px 16px 0px 1px #1b2469;
}
.Messages .send-message .input-message {
  border-radius: 0;
}
/* Footer */
/* Content Message */
.ContentMessages .message-text .cont {
  overflow-y: scroll;
  transition: 0.5s;
  height: 320px;
}
.ContentMessages .message-text .cont::-webkit-scrollbar {
  display: none;
}
/* Content Message */
