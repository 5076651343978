.SomeQuestion{
  margin-top:150px ;
  max-width: 810px;
}
.App[dir="rtl"] .SomeQuestion .accordion-button::after {
  margin-left: 0%;
  margin-right: auto;
}
.SomeQuestion .accordion-button::after{
  width: 23px;
  height: 23px;
  border: 1px solid #BEBEBE;
  border-radius: 50%;
  background-position: center;
  background-size: 15px;
}
@media (max-width: 600px) {
  .accordion-button {
    font-size: 14px;
  }
}

.accordion-button:focus {
  color: black !important;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: transparent;
  font-size: 20px;
  font-weight: 700;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.accordion-item {
  border: none;
  overflow: hidden;
}
/* Media */
@media (max-width:767px) {
  .accordion-button:not(.collapsed) {
    font-size: 14px;
    font-weight: 700;
  }
  .accordion-body{
    font-size: 15px;
  }
}