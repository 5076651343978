.NavbarVendor {
  box-shadow: 0 -15px 20px 0px black;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 200;
}
.NavbarVendor .logo {
  width: 75px;
}
/* Ads */
.NavbarVendor .ads {
  height: 77px;
}
/* Ads */
.Vendor[dir="ltr"] .language-cont .menu {
  right: 0;
}
.Vendor[dir="rtl"] .language-cont .menu {
  left: 0;
}
/*  */
.userAccount .useraccount-window {
  background-color: #fff;
  box-shadow: 0 4px 28px rgba(0, 0, 0, 0.19);
  left: 0;
  top: calc(100% + 10px);
  width: 160px;
}
/*  */
.icon-outline {
  box-shadow: 0 0 1px 3px var(--green-color);
  border-radius: 4px;
}
