.PopupSelectTypeServes {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #0000005c;
  z-index: 100000;
  width: 100%;
}
.PopupSelectTypeServes .types .type {
  flex-basis: 132px;
  transition: .3s;
}
.PopupSelectTypeServes .types .type:hover{
    transform: translateY(-10px);
}
.PopupSelectTypeServes .image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.PopupSelectTypeServes a h3 {
  color: var(--text-color);
}
.PopupSelectTypeServes .cancel {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
}
