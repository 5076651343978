.Taps .nav-tab div {
  color: #6a6a6a;
  border-bottom: 1px solid #c5c5c5;
}
.Taps .nav-tab .active {
  color: var(--text-color);
  border-bottom: 2px solid #f2c94c !important;
}
/* Nav Header */
.Taps .NavHeader {
  padding: 40px 0;
}
.Taps .NavHeader .menu {
  width: 136px;
  height: 40px;
  border-radius: 3px;
  border: 0.5px solid #bbb;
}

.Taps .NavHeader .menu span:first-child,
.Taps .NavHeader ul li {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}
/* Nav Header */
@media (max-width: 767px) {
  .Taps .NavHeader .menu {
    width: 110px;
  }
}
