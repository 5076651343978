.OrderFlat .head p {
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  max-width: 460px;
}

/* ChooseCar */
.OrderFlat .ChooseCar .choose button.active {
  border-color: #f2c94c;
  font-weight: 700;
  color: var(--text-color);
}
.OrderFlat .ChooseCar .choose button {
  border: 1px solid #e2e2e2;
  padding: 18px;
  width: 100%;
  border-radius: 8px;
  background: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #acacac;
}
/* Maps */
.OrderFlat .map {
  height: calc(100vh - 78px);
  width: 100%;
}
@media (min-width: 992px) {
  .OrderFlat .order-flat {
    max-width: 85%;
  }
  .OrderFlat .map {
    position: sticky;
    top: 80px;
  }
}

.OrderFlat .done {
  border-color: var(--green-color) !important;
  box-shadow: 0 0 0px 2px #248d5d;
}

.OrderFlat .done svg path {
  fill: #248d5d;
}
.OrderFlat .done .map-text {
  color: #248d5d;
}
