.ShareComponent .share-icon {
  gap: 32px;
}
.ShareComponent .share-icon {
  gap: 32px;
}
.ShareComponent .share-icon span {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  font-size: 30px;
}
.ShareComponent .share-icon span:nth-child(1) {
  color: #1877f2;
  background-color: rgb(24 119 242 / 10%);
}
.ShareComponent .share-icon span:nth-child(2) {
  color: #55acee;
  background-color: rgb(85 172 238 / 10%);
}
.ShareComponent .share-icon span:nth-child(3) {
  color: #ff4500;
  background-color: rgb(255 69 0 / 10%);
}
.ShareComponent .share-icon span:nth-child(4) {
  color: #006699;
  background-color: rgb(0 102 153 / 10%);
}
.ShareComponent .share-icon span:nth-child(5) {
  color: #64b161;
  background-color: rgb(100 177 97 / 10%);
}
.ShareComponent .share-icon span:nth-child(6) {
  color: #006699;
  background-color: rgb(0 102 153 / 10%);
}
@media (max-width: 767px) {
  .ShareComponent .share-icon {
    gap: 16px;
  }
  .ShareComponent .share-icon span {
    width: 43px;
    height: 43px;
    font-size: 20px;
  }
}
