.Comments .CommentComp {
  padding: 20px;
  border: 0.5px solid #d2d2d2;
  border-radius: 4px;
}
.Comments .CommentComp .profile {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.Comments .CommentComp .user-id .name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--text-color);
}
.Comments .CommentComp .user-id .time {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #acacac;
}
.Comments .CommentComp .buttons button {
  width: 24px;
  height: 24px;
}
.Comments .CommentComp .message {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--text-color);
  max-width: 521px;
}
.Comments .more {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
  width: 204px;
  text-align: center;
  border-color: var(--green-color) !important;
}
.Comments form .write-comment{
    border-radius: 6px;
background: #F7F7F7;
}
/* No Comments */
.Comments .no-comment{
  background-color: #f7f7f7;
  border-radius: 6px;
  height: 150px;
}